<template>
    <div class="container">
        <div class="columns is-centered content is-small title">
            <div class="column is-5-tablet is-4-desktop is-5-widescreen">
                <h2 class="mb-5 error" v-if="this.verifyErrorMessage" >{{this.verifyErrorMessage}}</h2>
                <h2 class="mb-5 " v-else > Verification Your Email Address {{this.$route.params.email}} success! </h2>

                <div class="columns is-centered pt-4">
                    <router-link class="button is-white is-four-fifths has-text-weight-semibold" :to="{path: goTo(), query: $storage.get('last_page_params')}">Back to <span class="page">{{goToName()}}</span></router-link>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import {ERROR} from "../const";
    export default {
        name: "VerifyScreen" ,
        data(){
            return {
                loading: false,
                verifyErrorMessage:'',
            }
        },
        methods:{
            ...mapActions(['DO_VERIFY']),
            doVerify(){
                this.DO_VERIFY({
                    email: this.$route.query.email,
                    signature: this.$route.query.signature
                })
                .then((response)=>{
                    console.log( response );
                })
                .catch((data)=>{
                    this.loading = false;
                    if (data && data.error && data.message) {
                        if(data.message === ERROR.VERIFY_FAILED){
                            this.verifyErrorMessage = "Verification your email address failed.";
                        } else if(data.message === ERROR.EMAIL_ALREADY_VERIFY){
                            this.verifyErrorMessage = "Your email already verified.";
                        } else {
                            this.verifyErrorMessage = "Undefined error.";
                        }

                    }
                })
            },
            goTo(){
                return this.$storage.get('last_page') ? this.$storage.get('last_page'): 'home';
            },
            goToName(){
                if (this.$storage.get('last_page') && this.$storage.get('last_page').split('/')[1]){
                    return this.$storage.get('last_page').split('/')[1]
                }else{
                    return 'home'
                }
            }
        },
        created(){
            this.doVerify();
        }
    }


</script>

<style lang="scss" scoped>
    .container{
        margin: 150px auto;
    }
    .error {
        color: red;
    }

    .entertainment {
        body {
            background-color: black !important;
        }
        .title {
            padding-top: 10rem;
            color: #d0d0d0;
            h2{
                color: #F5F5F5;
            }
        }
        .container {
            margin: 80px auto 0;
        }
    }
</style>
